@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

.textWhiteFrame {
  font-family: 'Montserrat', sans-serif;
  margin-top: 5%;
  width: 550px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: lighter;
  height: 550px;
}
h1 {
  letter-spacing: 10px;
  margin-top: 10vh;
  /* font-weight: 900; */
}

.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
